body {
    background: url("../../assets/img/fond-kolekti.jpg") no-repeat center center fixed;
    font-family: 'Open Sans', sans-serif;
//    box-shadow: inset 8px 500px 70vh -87px rgba(0, 146, 205,0.6);
    color: #8a8a8a;
    background-color: #00bdff;
}

h1 {
  max-width: 65%;
  margin: 2em auto;
}

h1, h2 {
  font-family: 'Roboto Slab', serif;
  color : #00bdff;
//  text-shadow: 1px 1px 2px #fff;
}

h2 {
  margin: 2em 0;
}

h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
}

h3 {
  font-weight: bold;
}

.menu a {
    color: #8a8a8a;
}

.sticky {
  box-shadow: -1px 5px 25px -1px rgba(148,148,148,1);
}

header {
  background: transparent;
  min-height: 400px;
  min-height: 90vh;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  color : #fff;
}

@media (max-width: 768px) {
  header {
  min-height: 0 !important;
  padding-top : 2em;
  }
}


section {
  padding : 2em;
}

.intro {
  text-align: center;
  margin: -2em 0 4em 0;
}

h2, h3 {
  text-align: center;
}

.button {
  background-color : rgba(23,121,186, 0.5);
  padding: 0.5em 0.85em;
}

blockquote {
  color : #00bdff;
    text-align: center;
  font-size: 115%;
  font-weight: 600;
  font-style: italic;
  max-width : 85%;
  margin: 2em auto 1em auto;
  border-left: 0;
}

blockquote::before {
  content: " ";
  display: block;
  width : 30px;
  margin: 0 auto;
  padding: 1em;
  border-top : 4px solid #00bdff;
}

.quote-signature {
  text-align: center;
  color : #00bdff;
  margin: 0;
}

.quote-signature {
  &::before, &::after {
    content : " - "
  }
}

.bg-dark {
//      box-shadow: inset 8px 500px 70vh -87px rgba(0, 146, 205,0.6);
    h1 {
      font-size: 2em;
      text-align: center;
    }
    h2 {
      color : #fff;
    }
    h3 {
      color : #fff;;
    }
}

.bg-light {
    background: #fff;
    h1 {
      font-size: 2em;
      text-align: center;
    }
    h2 {

    }
    h3 {
      color: #767676;
    }
}

.marketing-hero .cell {
  text-align: center;
  max-width: 960px;
}

.headline {
  font-family: 'Roboto Slab', sans-serif !important;
  font-size: 2.5em;
  line-height: 1.5;
  margin: 1.5em 0 2.5em;

}

.topbar-title img {
  min-height : 3.85em;
}

.sticky {
  background: $white;
}

#offres {
  background: #edf6f7;
}

#features {
  background: transparent;
  min-height: 400px;
  min-height: 60vh;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  color : #fff;
  h2 {
    margin-top: 0;
  }
}

.orbit-caption {
}

.orbit-container {

}

.orbit-previous, .orbit-next {
border-radius: 100%;
padding: 0.25rem;
color : #00bdff;
}
.orbit-previous {
  left : -8em;
}

.orbit-next {
  right : -8em;
}

.orbit-slide {
  h3 {
    font-size: 1.25em;
  }
  p {
    text-align: center;
  }
  @media screen and (min-width: 1024px) {
    p {
      font-weight: 600;
      margin : 2em auto;
      display: block;
      max-width: 50%;
    }
  }

}

.orbit-bullets button {
    width: 0.75rem;
    height: 0.75rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca;
}

.orbit-bullets button.is-active {
    background-color: #00bdff;
    border : 1px solid rgba(10, 10, 10, 0.5);
}

#apropos .medium-4 p {
  text-align: center;
}

#realisations {
  background: #e6e6e6;
  h2 {
    color : #00bdff;
    text-align: left;
    font-size: 1em;
    font-weight: bold;
    margin: 0;
  }
  .thumbnail {
      display: table-cell;
      max-width: 100%;
      margin-bottom: 1rem;
      border: none;
      box-shadow: none;
      background: #fff;
      border-radius: 100%;
      min-width : 120px;
      height: 120px;
      text-align: center;
      vertical-align: middle;
  }
  .media-object img {
      max-width: 90px;
      border-radius: 100%;
  }

}




footer {
  background-color: #f9f9f9;
  box-shadow: inset 0 8px 8px -8px rgba(0,0,0,0.1);
  padding : 2em;
}


$topbar-height: 3em;
$topbar-initial-height: 6em;
$topbar-transition: all .4s ease;

.top-bar {
  height: 6.25em;
  transition: $topbar-transition;

  .title-area {
    top: 3rem;
    transition: $topbar-transition;
  }

  .top-bar-section li:not(.has-form) a:not(.button) {
    height: 6em;
    line-height: 11;
  }

  .top-bar-section .has-dropdown > a:after {
    top: 4.4rem;
    transition: $topbar-transition;
  }
}



.top-bar.active{
  height:$topbar-height;
    .title-area {
    top: 1rem;
  }
    .top-bar-left a {
    padding : 0;
    }
    .topbar-title img {
        min-height: 1.85em;
        transition: $topbar-transition;
    }


  .top-bar-section li:not(.has-form) a:not(.button) {
    height: 3rem;
    line-height: 6;
  }

  .top-bar-section .has-dropdown > a:after {
    top: 2.2rem;
  }
}

.kolekti-emphasis {
  font-weight: bold;
}

#realisations {
  .kolekti-emphasis::after {
    content: " ";
    display: block;
    width: 100px;
    margin: 2em auto;
    border-top: 1px solid #8a8a8a;
    transform: translateX(-68px);
  }
  p {
    margin-bottom : 0;
  }
  .media-object:last-of-type .kolekti-emphasis::after {
    display: none;
  }
}

.kolekti-cta {
  h2 {
    margin : 0 1em;
    text-align: right;
  }
  h2 span {
    display: block;
    font-size: 65%;
    font-weight: 300;
  }
  .grid-x {
    justify-content: center;
  }
}


#quisommesnous .cell {
  h3 a {
    color: #00bdff;
  }
  img {
    border-radius: 100%;
    display: block;
    margin: 0 auto;
  }
  p {
    text-align: center;
    font-size: 90%;
  }
  p a {
    text-decoration: underline;
  }
}

.kolekti-member-item {
  h4 {
    font-weight: 600;
    font-size: 1em;
    text-align: center;
    margin: 1.2em 0;
  }
}


.kolekti-list div {
  position: relative;
  padding : 1em;
  &::after, &::before {
    position: absolute;
  }
  &::before {
    left : 0;
    top : 50%;
    transform: translateY(-50%);
  }
  &::after {
    right : 0;
    top : 50%;
    transform: translateY(-50%);
  }
}

.kolekti-list .kolekti-list-item:nth-child(odd) {
    text-align: right;
    padding-right: 4em;
    h3 {
      text-align: right;
    }
}

.kolekti-list .kolekti-list-item:nth-child(even) {
    text-align: left;
    padding-left: 4em;
    h3 {
      text-align: left;
    }
}


.button {
    line-height: inherit;
    box-shadow: -0px 8px 0px -4px rgba(255,255,255,1);
    border : 2px solid rgba(255, 255, 255, 0.5);
}

#offres {
  th {
    text-align: center;
    vertical-align: top;
  }
  th span {
    display: block;
    background-color: #00bdff;
    padding: 0.65em;
    margin: 0.5em 0;
    color: #fff;
  }
  th p:first-of-type {
    font-weight: 300;
    font-style: italic;
    min-height: 6em;
  }
  td {
    text-align: center;
  }
  td:first-of-type {
    text-align: left;
  }
  .icon-ok span {
    display: none;
  }
  p.footer {
    font-style: italic;
    font-size: 0.9em;
  }
}

#contactform p {
  text-align: center;
}

#back-top a {
  display: block;
  position: fixed;
  bottom:20px;
  right: 2%;
  z-index: 100;
}

#back-top span {
  text-indent: -9999999px;
  display: block;
}


.icon-angle-double-up:before {
  color: #fff !important;
  background: #00bdff;
  padding : 0.5em;
}

.button.icon-angle-double-up::before,
#back-top .icon-angle-double-up:after {
  display: none;
}

.button.icon-angle-double-up:after {
  color: #fff;
  display: inline-block;
  font-size: 0.8em;
}
