@font-face {
  font-family: 'kolekti-ish';
  src: url('../font/kolekti-ish.eot?3412929');
  src: url('../font/kolekti-ish.eot?3412929#iefix') format('embedded-opentype'),
       url('../font/kolekti-ish.woff2?3412929') format('woff2'),
       url('../font/kolekti-ish.woff?3412929') format('woff'),
       url('../font/kolekti-ish.ttf?3412929') format('truetype'),
       url('../font/kolekti-ish.svg?3412929#kolekti-ish') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'kolekti-ish';
    src: url('../font/kolekti-ish.svg?3412929#kolekti-ish') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before,
 [class^="icon-"]:after, [class*=" icon-"]:after {
   font-family: "kolekti-ish";
   font-style: normal;
   font-weight: normal;
   font-size: 160%;
   speak: none;
   display: block;
   text-decoration: inherit;
   color : #00bdff;

   /* you can be more comfortable with increased icons size */
   /* font-size: 120%; */

   /* Font smoothing. That was taken from TWBS */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;

   /* Uncomment for 3D effect */
   /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

   /* opacity: .8; */

   /* For safety - reset parent styles, that can break glyph codes*/
   font-variant: normal;
   text-transform: none;

   /* fix buttons height, for twitter bootstrap */
   line-height: 1em;

 }

 [class^="icon-"]:before, [class*=" icon-"]:before {

/*  width: 1em; */
  margin-right: .2em;
  text-align: center;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  margin-bottom: 1.25em;
}

[class^="icon-"]:after, [class*=" icon-"]:after {

/*  width: 1em; */
 margin-left: .2em;
 text-align: center;

 /* Animation center compensation - margins should be symmetric */
 /* remove if not needed */
 margin-right: .2em;


}

.icon-flag:before { content: '\e800'; } /* '' */
.icon-cogs:after { content: '\e801'; } /* '' */
.icon-group:after { content: '\e802'; } /* '' */
.icon-wrench:before { content: '\e803'; } /* '' */
.icon-install:after { content: '\e803'; } /* '' */
.icon-users-1:before { content: '\e804'; } /* '' */
.icon-users-2:before { content: '\e805'; } /* '' */
.icon-forward:after { content: '\e806'; } /* '' */
.icon-ok:before { content: '\e807'; } /* '' */
.icon-users:before { content: '\f064'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-up:after { content: '\f102'; transform: rotate(180deg);} /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-database:before { content: '\f1c0'; } /* '' */
